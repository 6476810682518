import React, { Component } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import {Field, reduxForm, change} from "redux-form";
import store from "../../App/store";
import {updateUserAction} from "../../../redux/actions/usersActions";
import noty from '../../../utils/noty'
import { CONVERSIONS_TYPES_EMPTY_SHAPE } from "../../../constants/conversions";

class Postback extends Component {

    constructor(props) {
        super(props);
        this.state = {
            postbacks: [],
            conversion_type_to_multiple_postback: {},
        };
        this.curlyStart = this.curlyStart.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.curlyEnd = this.curlyEnd.bind(this)
    }

    componentDidMount() {
        const user = store.getState().users.currentUser || {};
        let postbacks = [''];
        if (user && user.postbacks && user.postbacks.length) {
            postbacks = user.postbacks;
        }
        const conversion_type_to_multiple_postback = user.conversion_type_to_multiple_postback || {};
        const { dispatch } = this.props;
        postbacks.forEach((postback, index) => {
            dispatch(change('postback_form', `postback_${index}`, postback));
        });
        const keysArray = Object.keys(conversion_type_to_multiple_postback);
        if (keysArray.length) {
            keysArray.forEach((key) => dispatch(change('postback_form', key, conversion_type_to_multiple_postback[key])))
        }
        this.setState({ postbacks, conversion_type_to_multiple_postback });
    }

    handleChange = (index, event) => {
        const postbacks = [...this.state.postbacks];
        postbacks[index] = event.target.value.trim();
        this.setState({ postbacks });
    }

    handleSubmit = (event) =>{
        event.preventDefault()
        const data = this.state;
        data.postbacks = data.postbacks.filter((postback) => postback)
        store.dispatch(updateUserAction(data))
            .then(() => noty({ text: 'Postback saved successfully', type: 'success' }))
    }

    curlyStart() {
        return '{'
    }

    curlyEnd() {
        return '}'
    }

    renderHints = () => {
        return (
            <span className="form__form-group-description">
                Available parameters are: {this.curlyStart()}ref_id{this.curlyEnd()} , {this.curlyStart()}aff_payout{this.curlyEnd()}, {this.curlyStart()}status{this.curlyEnd()}.
                <br />
                {this.curlyStart()}ref_id{this.curlyEnd()} - unique click id we received from you
                <br />
                {this.curlyStart()}aff_payout{this.curlyEnd()} - transaction payout
                <br />
                {this.curlyStart()}status{this.curlyEnd()} - conversion status. Can be empty or with a set of designated values approved, pending, declined, other
                <br />
                {this.curlyStart()}type{this.curlyEnd()} - conversion type. Can be empty or can pass conversion name
                <br />
                {this.curlyStart()}coupon_value{this.curlyEnd()} - coupon field from the campaign
                <br />
                {this.curlyStart()}postbackid{this.curlyEnd()} - unique conversion id
            </span>
        )
    }

    handleChangeMultiPostback(event) {
        const { name, value } = event.target;
        const { conversion_type_to_multiple_postback } = this.state;
        conversion_type_to_multiple_postback[name] = value;
        this.setState({ conversion_type_to_multiple_postback });
    }

    _renderMultiPostbackFields() {
        const { conversion_type_to_multiple_postback } = this.state;
        const users = store.getState().users || {};
        const usersSettings = users.settings || {};
        const conversion_types = usersSettings && usersSettings.goals || { ...CONVERSIONS_TYPES_EMPTY_SHAPE }
        const conversionTypes = { ...(conversion_types || CONVERSIONS_TYPES_EMPTY_SHAPE) };
        const keysArray = Object.keys(conversion_type_to_multiple_postback);

        return keysArray.map((keyItem) => {
            return (
              <div className="form__form-group" style={{ display: 'flex', alignItems: 'center' }}>
                            <span className="form__form-group-label mr-10" style={{ minWidth: '200px' }}>
                                {keyItem === 'default_type' ? 'Default Conversion' : (conversionTypes[keyItem] || keyItem)}
                            </span>
                  <div className="form__form-group-field">
                      <Field
                        id={keyItem}
                        name={keyItem}
                        component="input"
                        type={'text'}
                        value={conversion_type_to_multiple_postback[keyItem] || ''}
                        placeholder="https://yourdomain.com/postback?clickid={ref_id}&revenue={aff_payout}&status={status}&coupon={coupon_value}"
                        onChange={this.handleChangeMultiPostback.bind(this)}
                      />
                  </div>
              </div>
            );
        });
    }

    render() {
        return (
            <Col md={6} style={{margin: 'auto'}}>
                <Card>
                    <CardBody>
                        <div className="card__title">
                            <h5 className="bold-text">Postback setup</h5>
                        </div>
                        <form className="form"  onSubmit={this.handleSubmit}>
                            <div className="form__form-group">
                                <span className="form__form-group-label">Postback URL</span>
                                {this.state.postbacks.map((postback, index) => (
                                    <div className="form__form-group-field" key={index} style={{ marginBottom: 5 }}>
                                        <Field
                                            name={`postback_${index}`}
                                            component="input"
                                            type="text"
                                            value={postback}
                                            placeholder="https://yourdomain.com/postback?clickid={ref_id}&revenue={aff_payout}&status={status}&coupon={coupon_value}"
                                            onChange={(e) => this.handleChange(index, e)}
                                        />
                                    </div>
                                ))}
                            </div>
                            {this._renderMultiPostbackFields()}
                            <div className="form__form-group">
                                {this.renderHints()}
                            </div>
                            <button type="submit" className="btn btn-primary account__btn--small" to="/pages/one">Save</button>
                        </form>
                    </CardBody>
                </Card>
            </Col>
        )
    }
}

export default reduxForm({
    form: 'postback_form',
})(Postback)
