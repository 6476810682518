const METRICS_DATA = [
  {
    value: 'impressions',
    label: 'Impressions',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#f15854' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#f15854',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
    aggregator: (_sumBy, data) => _sumBy(data, (el) => el['impressions']),
    currency: false
  },
  {
    value: 'clicks',
    label: 'Clicks',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#5da5da' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#5da5da',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
    aggregator: (_sumBy, data) => _sumBy(data, (el) => el['clicks']),
    currency: false
  },
  {
    value: 'conversions',
    label: 'Conversions',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#82ca9d' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#82ca9d',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
    aggregator: (_sumBy, data) => _sumBy(data, (el) => el['conversions']),
    currency: false
  },
  {
    value: 'roi',
    label: 'ROI',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#0100a8' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#0100a8',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
    aggregator: (_sumBy, data) => {
      let result = 'N/A'
      if (data.length !== 0 && _sumBy(data, (el) => el['cost']) !== 0) {
        if (_sumBy(data, (el) => el['total_revenue']) === undefined) {
          return result
        }
        result = `${(((_sumBy(data, (el) => el['total_revenue']) - _sumBy(data, (el) => el['cost'])) / _sumBy(data, (el) => el['cost'])) * 100).toFixed(2)}%`
      }
      return result
    },
  },
  {
    value: 'total_conversions',
    label: 'Total Actions',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#ca2900' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#ca2900',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
    aggregator: (_sumBy, data) => _sumBy(data, (el) => el['total_conversions']),
    currency: false
  },
  {
    value: 'transactions',
    label: 'Transactions',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#f19500' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#f19500',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
    aggregator: (_sumBy, data) => _sumBy(data, (el) => el['transactions']),
    currency: false
  },
  {
    value: 'epc',
    label: 'epc',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#8a391c' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#8a391c',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
    aggregator: (_sumBy, data) => {
      let result = 'N/A'
      if(data.length !== 0) {
        if (_sumBy(data, (el) => el['total_revenue']) === undefined) {
          return result
        }
        result = `${((_sumBy(data, (el) => el['total_revenue']) / _sumBy(data, (el) => el['clicks']))).toFixed(2)}`
      }
      return result
    },
    currency: true
  },
  {
    value: 'cost',
    label: 'Commission',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#000000' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#000000',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
    aggregator: (_sumBy, data) => `${(_sumBy(data, (el) => el['cost'])).toFixed(2)}`,
    currency: true
  },
  {
    value: 'profit',
    label: 'Profit',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#f100a9' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#f100a9',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
    aggregator: (_sumBy, data) => {
      let result = 'N/A'
      if(data.length !== 0) {
        if (_sumBy(data, (el) => el['profit']) === undefined) {
          return result
        }
        result = `${(_sumBy(data, (el) => el['profit'])).toFixed(2)}`
      }
      return result
    },
    currency: true
  },
  ...(Array.from({ length: 40 }, (el, i) => i + 1).map((count) => (
    {
      value: `convtype${count}`,
      yaxisProperties: {
        type: 'number',
        tick: { fill: '#ce004a' },
        allowDecimals: false,
      },
      lineChartProperties: {
        stroke: '#ce004a',
        dot: { fill: '#ffffff', strokeWidth: 1 },
        strokeWidth: 2,
      },
      aggregator: (_sumBy, data) => _sumBy(data, (el) => el[`convtype${count}`]),
      currency: false,
    }
  ))),
  ...(Array.from({ length: 40 }, (el, i) => i + 1).map((count) => (
    {
      value: `revenuetype${count}`,
      yaxisProperties: {
        type: 'number',
        tick: { fill: '#ff00b4' },
        allowDecimals: false,
      },
      lineChartProperties: {
        stroke: '#ff00b4',
        dot: { fill: '#ffffff', strokeWidth: 1 },
        strokeWidth: 2,
      },
      aggregator: (_sumBy, data) => {
        let result = 'N/A';
        if (data.length !== 0) {
          if (_sumBy(data, (el) => el[`revenuetype${count}`]) === undefined) {
            return result;
          }
          result = `${(_sumBy(data, (el) => el[`revenuetype${count}`])).toFixed(2)}`;
        }
        return result;
      },
      currency: true,
    }
  ))),
  {
    value: 'revenue',
    label: 'Revenue',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#8884d8' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#8884d8',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
    aggregator: (_sumBy, data) => {
      let result = 'N/A'
      if(data.length !== 0) {
        if (_sumBy(data, (el) => el['revenue']) === undefined) {
          return result
        }
        result = `${(_sumBy(data, (el) => el['revenue'])).toFixed(2)}`
      }
      return result
    },
    currency: true
  },
  {
    value: 'total_revenue',
    label: 'Total Revenue',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#0042d8' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#0042d8',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
    aggregator: (_sumBy, data) => {
      let result = 'N/A'
      if(data.length !== 0) {
        if (_sumBy(data, (el) => el['total_revenue']) === undefined) {
          return result
        }
        result = `${(_sumBy(data, (el) => el['total_revenue'])).toFixed(2)}`
      }
      return result
    },
    currency: true
  },
  {
    value: 'cr',
    label: 'Conversion rate',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#91f100' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#91f100',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
    aggregator: (_sumBy, data) => {
      let result = 'N/A'
      if(data.length !== 0) {
        result = `${((_sumBy(data, (el) => el['total_conversions']) / _sumBy(data, (el) => el['clicks'])) * 100).toFixed(2)}%`
      }
      return result
    },
    currency: false
  },
]

const DASHBOARD_METRICS_LIST = ['metrics_A', 'metrics_B', 'metrics_C', 'metrics_D']

const DASHBOARD_BIGGEST_CHANGES_OPTIONS = [
  {
    value: 'yesterday_vs_day_before_yesterday',
  },
  {
    value: 'last_7_days_vs_previous_7_days',
  },
  {
    value: 'last_2_weeks_vs_previous_2_weeks',
  },
  {
    value: 'last_30_days_vs_previous_30_days',
  },
]

const DASHBOARD_GROUPING_OPTIONS = [
  {
    value: 'date',
  },
  {
    value: 'source',
    sharedAccessRights: 'sources',
  },
  {
    value: 'campaign',
    sharedAccessRights: 'campaigns',
  },
  {
    value: 'offer',
    sharedAccessRights: 'offers',
  },
  {
    value: 'network',
    sharedAccessRights: 'networks',
  },
]

const DASHBOARD_BIGGEST_CHANGE_DEF_GROUPING = ['date', 'campaign', 'source']
const METRICS_DATA_CHARTS_COLORS = ['#1B9E77', '#D95F02', '#7570B3', '#E7298A', '#66a61e'];
const METRICS_DATA_CHARTS_DEF_COLOR = '#000000';
const DASHBOARD_EXCLUDE_METRICS = ['roi', 'profit', 'revenue', 'total_revenue']
const CONVERSION_DASHBOARD_SCOPE = 'conversion_dashboard'
const TRAFFIC_DASHBOARD_SCOPE = 'traffic_dashboard'

export {
  METRICS_DATA,
  METRICS_DATA_CHARTS_COLORS,
  METRICS_DATA_CHARTS_DEF_COLOR,
  DASHBOARD_GROUPING_OPTIONS,
  DASHBOARD_BIGGEST_CHANGES_OPTIONS,
  DASHBOARD_BIGGEST_CHANGE_DEF_GROUPING,
  DASHBOARD_METRICS_LIST,
  DASHBOARD_EXCLUDE_METRICS,
  CONVERSION_DASHBOARD_SCOPE,
  TRAFFIC_DASHBOARD_SCOPE,
}
