import React, { Component } from 'react';
import _ from 'lodash'

import Metrics from './Metrics'
import ChartContainer from './ChartContainer'
import { getSavedOrderOfColumns, renderMetricTitle } from '../../../../utils';

import {
  METRICS_DATA,
  DASHBOARD_METRICS_LIST,
  DASHBOARD_EXCLUDE_METRICS,
  METRICS_DATA_CHARTS_COLORS,
  METRICS_DATA_CHARTS_DEF_COLOR,
} from '../../../../constants/dashboard'

class Graphs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      metrics: {
        metrics_A: {
          options: METRICS_DATA,
          value: '',
          label: '',
          index: null,
          lineChartProperties: {},
        },
        metrics_B: {
          options: METRICS_DATA,
          value: '',
          label: '',
          index: null,
          lineChartProperties: {},
        },
        metrics_C: {
          options: METRICS_DATA,
          value: '',
          label: '',
          index: null,
          lineChartProperties: {},
        },
        metrics_D: {
          options: METRICS_DATA,
          value: '',
          label: '',
          index: null,
          lineChartProperties: {},
        },
        lineCharts: [],
      },
      customMetricsData: METRICS_DATA,
    }
    this.saveSelectedMetrics = this.saveSelectedMetrics.bind(this)
    this.prepareSortedReportColumns = this.prepareSortedReportColumns.bind(this)
    this.disableSelectedMetrics = this.disableSelectedMetrics.bind(this)
    this.handleChangeMetrics_A = this.handleChangeMetrics_A.bind(this)
    this.handleChangeMetrics_B = this.handleChangeMetrics_B.bind(this)
    this.handleChangeMetrics_C = this.handleChangeMetrics_C.bind(this)
    this.handleChangeMetrics_D = this.handleChangeMetrics_D.bind(this)
    this.renderMetricsChartColors = this.renderMetricsChartColors.bind(this)
    this.renderMetricsColors = this.renderMetricsColors.bind(this)
  }

  componentDidMount() {
    const { customMetricsData, metrics } = this.state
    const { users } = this.props
    const usersSettings = users.settings
    let metricData = customMetricsData

    if (_.has(users, 'currentUser.settings.dashboard')) {
      if (users.currentUser.settings.dashboard.metrics) {
        const settingsMetrics = users.currentUser.settings.dashboard.metrics
        DASHBOARD_METRICS_LIST.map((metric, i) => {
          this.disableSelectedMetrics({
            value: settingsMetrics[metric].value,
            label: settingsMetrics[metric].label,
            currentMetric: metric,
            otherMetrics: DASHBOARD_METRICS_LIST.filter(el => el !== metric),
            lineChartProperties: { ...metric.lineChartProperties },
          }, () => null)
        })
      }
    } else {
      const tables = usersSettings && usersSettings.settings ? usersSettings.settings : []
      metricData.map(option => option.label = renderMetricTitle(option.value, option.label, usersSettings))
      if (tables.length !== 0) {
        metricData = getSavedOrderOfColumns(metricData, tables, 'value')
        DASHBOARD_EXCLUDE_METRICS.map(metric => {
          const isNeedToExcludeElement = tables.find(el => el.id === metric)
          if (isNeedToExcludeElement) {
            if (!isNeedToExcludeElement.enabled) {
              metricData = metricData.filter(el => el.value !== isNeedToExcludeElement.id)
            }
          }
        })
      }
      DASHBOARD_METRICS_LIST.map(metric => metrics[metric].options = metricData)
      this.setState({ customMetricsData: metricData }, () => {
        this.disableSelectedMetrics({
          value: metricData[0] && metricData[0].value ? metricData[0].value : null,
          label: metricData[0] && metricData[0].label ? metricData[0].label : '',
          options: metricData,
          currentMetric: 'metrics_A',
          otherMetrics: DASHBOARD_METRICS_LIST.filter(metric => metric !== 'metrics_A'),
        }, this.saveSelectedMetrics)
        this.disableSelectedMetrics({
          value: metricData[1] && metricData[1].value ? metricData[1].value : null,
          label: metricData[1] && metricData[1].label ? metricData[1].label : '',
          options: metricData,
          currentMetric: 'metrics_B',
          otherMetrics: DASHBOARD_METRICS_LIST.filter(metric => metric !== 'metrics_B'),
        }, this.saveSelectedMetrics)
        this.disableSelectedMetrics({
          value: metricData[2] && metricData[2].value ? metricData[2].value : null,
          label: metricData[2] && metricData[2].label ? metricData[2].label : '',
          options: metricData,
          currentMetric: 'metrics_C',
          otherMetrics: DASHBOARD_METRICS_LIST.filter(metric => metric !== 'metrics_C'),
        }, this.saveSelectedMetrics)
        this.disableSelectedMetrics({
          value: metricData[3] && metricData[3].value ? metricData[3].value : null,
          label: metricData[3] && metricData[3].label ? metricData[3].label : '',
          options: metricData,
          currentMetric: 'metrics_D',
          otherMetrics: DASHBOARD_METRICS_LIST.filter(metric => metric !== 'metrics_D'),
        }, this.saveSelectedMetrics)
      })
    }
  }

  componentWillUnmount() {
    METRICS_DATA.map((el,i) => el.disabled = false)
  }

  saveSelectedMetrics(metricData) {
    // store.dispatch(updateUserAction({ settings: { dashboard: { metrics: metricData } } }, { notice: false }))
  }

  prepareSortedReportColumns(metrics) {
    const metricsReportsColumns = []
    let metricsKey = Object.keys(metrics)
    metricsKey.splice(-1,1)
    metricsKey.map((el, i) => {
      if (metrics[el].value) {
        metricsReportsColumns.push(metrics[el].value)
      }
    })
    return metricsReportsColumns
  }

  disableSelectedMetrics({ value, label, options, currentMetric, otherMetrics }, saveMetrics) {
    const { metrics } = this.state
    const { renderReportColumns } = this.props
    metrics.lineCharts = []
    const metrics_currentMetric = metrics[currentMetric]
    const lineChartData = METRICS_DATA.find((el) => el.value === value)
    metrics_currentMetric.value = value
    metrics_currentMetric.label = label
    metrics_currentMetric.options = options
    metrics_currentMetric.lineChartProperties = { ...(lineChartData ? lineChartData.lineChartProperties : lineChartData) }
    if (metrics_currentMetric.index !== null) {
      otherMetrics.map((el) => {
        metrics[el].options = options
        metrics[el].options[metrics_currentMetric.index].disabled = false
      })
    }
    const index = metrics_currentMetric.options.findIndex((el) => el.value === value)
    metrics_currentMetric.index = (value === null || index < 0) ? null : index
    if(value) {
      otherMetrics.map((el) => {
        metrics[el].options[metrics_currentMetric.index].disabled = true
      })
    }

    const metricsReportsColumns = this.prepareSortedReportColumns(metrics)
    metrics_currentMetric.options.map((el, i) => {
      if (el.disabled) {
        metrics.lineCharts.push(el)
      }
    })
    metrics.lineCharts.sort((a, b) => metricsReportsColumns.indexOf(a.value) - metricsReportsColumns.indexOf(b.value))
    this.setState({ metrics }, () => {
      renderReportColumns(this.state.metrics.lineCharts)
      saveMetrics(this.state.metrics)
    })
  }

  handleChangeMetrics_A(option) {
    const { customMetricsData } = this.state
    this.disableSelectedMetrics({
      value: option ? option.value : option,
      label: option ? option.label : '',
      options: customMetricsData,
      currentMetric: 'metrics_A',
      otherMetrics: ['metrics_B','metrics_C','metrics_D'],
    }, this.saveSelectedMetrics)
  }

  handleChangeMetrics_B(option) {
    const { customMetricsData } = this.state
    this.disableSelectedMetrics({
      value: option ? option.value : option,
      label: option ? option.label : '',
      options: customMetricsData,
      currentMetric: 'metrics_B',
      otherMetrics: ['metrics_A','metrics_C','metrics_D'],
    }, this.saveSelectedMetrics)
  }

  handleChangeMetrics_C(option) {
    const { customMetricsData } = this.state
    this.disableSelectedMetrics({
      value: option ? option.value : option,
      label: option ? option.label : '',
      options: customMetricsData,
      currentMetric: 'metrics_C',
      otherMetrics: ['metrics_A','metrics_B','metrics_D'],
    }, this.saveSelectedMetrics)
  }

  handleChangeMetrics_D(option) {
    const { customMetricsData } = this.state
    this.disableSelectedMetrics({
      value: option ? option.value : option,
      label: option ? option.label : '',
      options: customMetricsData,
      currentMetric: 'metrics_D',
      otherMetrics: ['metrics_A','metrics_B','metrics_C'],
    }, this.saveSelectedMetrics)
  }

  renderMetricsChartColors(metrics) {
    if (metrics.lineCharts) {
      metrics.lineCharts.forEach((charts, index) => {
        if (charts.lineChartProperties) {
          charts.lineChartProperties.stroke = METRICS_DATA_CHARTS_COLORS[index]
            ? METRICS_DATA_CHARTS_COLORS[index] : METRICS_DATA_CHARTS_DEF_COLOR;
        }
        if (charts.yaxisProperties && charts.yaxisProperties.tick) {
          charts.yaxisProperties.tick.fill = METRICS_DATA_CHARTS_COLORS[index]
            ? METRICS_DATA_CHARTS_COLORS[index] : METRICS_DATA_CHARTS_DEF_COLOR;
        }
      });
    }
    return metrics;
  }

  renderMetricsColors(metrics) {
    if (metrics.lineCharts) {
      DASHBOARD_METRICS_LIST.forEach((metric, index) => {
        if (metrics[metric].lineChartProperties && metrics[metric].lineChartProperties.stroke) {
          metrics[metric].lineChartProperties.stroke = METRICS_DATA_CHARTS_COLORS[index]
            ? METRICS_DATA_CHARTS_COLORS[index] : METRICS_DATA_CHARTS_DEF_COLOR;
        }
      });
    }
    return metrics;
  }

  render() {
    const { metrics } = this.state
    const { stat, scope, users } = this.props
    return (
      <div className="form pl-0 pt-0">
        <Metrics
          users={users}
          metrics={this.renderMetricsColors(metrics)}
          stat={stat}
          handleChangeMetrics_A={this.handleChangeMetrics_A}
          handleChangeMetrics_B={this.handleChangeMetrics_B}
          handleChangeMetrics_C={this.handleChangeMetrics_C}
          handleChangeMetrics_D={this.handleChangeMetrics_D}
        />
        <div className="form__form-group">
          <div className="form__form-group-field">
            <ChartContainer
              scope={scope}
              metrics={this.renderMetricsChartColors(metrics)}
              stat={stat}
            />
          </div>
        </div>
      </div>
    )
  }

}

Graphs.defaultProps = {
  users: {},
  usersSettings: {},
}

export default Graphs
